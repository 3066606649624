/* 
  We've already included normalize.css. 

  But we'd like a modern looking boilerplate. 
  Clean type, sans-serif, and a nice color palette. 
  
*/

body {
  font-family: sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #999;
  background-color: black;
  text-align: center;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: sans-serif;
  font-weight: 600;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

#app {
  padding: 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

h1 {
  color: white;
}

b {
  color: white;
  font-weight: bold;
}

a {
  color: #ccc;
  text-decoration-line: underline;
  text-underline-offset: 3px;
  text-decoration-color: #555;
}

.signup {
  padding: 8px 14px;
  background: white;
  color: black;
  border-radius: 3px;
}
